// react
import React, { useLayoutEffect } from 'react';

// packages
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// hooks
import { useHistory } from 'react-router-dom';

// layout
import V1Layout from 'layouts/V1';

// components
import HeaderDescription from 'components/HeaderDescription';

const PaymentFailure = function () {
  // hooks
  const history = useHistory();

  useLayoutEffect(() => {
    history.replace({
      pathname: '/payment-failure',
      search: '?type=recharge',
    });
  });

  return (
    <V1Layout forceShowLayoutItems shouldShowLogo={false}>
      <Stack alignItems="center">
        <HeaderDescription title="Payment Failed">
          <Typography className="text-center" my={3}>
            We are unable to accept payment at this time, please try again
            later.
          </Typography>
        </HeaderDescription>
      </Stack>
    </V1Layout>
  );
};

export default PaymentFailure;
